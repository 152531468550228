<template>
    <div class="admin-settings-trnaslations">
        <b-card class="cms-pages-admin" :title="$t('Users')">
            <b-row>
                <b-col cols="12">
                    <good-data-table 
                        ref="userTable" 
                        :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" 
                        :data-column="'response.responseData.data'"
                        :api-endpoint="'/admin/users'"
                         @add-new-info="toggelModal = true" 
                    />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
    
<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { dateTimeFormat, isEmpty } from '@/utilities';
export default {
    name: "UserListing",
    components: {
        GoodDataTable,
    },
    data: () => ({
    }),
    computed: {
        fields: {
            get() {
                return [
                    {
                        field: 'first_name',
                        label: 'First Name',
                        sortable: false,
                    },
                    {
                        field: 'last_name',
                        label: 'Last Name',
                        sortable: false,
                    },
                    {
                        field: 'email',
                        label: 'Email',
                        sortable: false,
                    },
                    {
                        field: 'login_by',
                        label: 'Source',
                    },
                    {
                        field: 'mobile_no',
                        label: 'Mobile',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => `${props.country_code}${props.mobile}`,
                    },
                    // {
                    //     field: 'wallet_balance',
                    //     label: 'Wallet Balance',
                    //     sortable: false,
                    //     useHtml: true,
                    //     renderer: props => {
                    //         if(isEmpty(props.wallet_balance)) return 0;
                    //         if(isEmpty(props.currency_symbol)) return props.wallet_balance;
                    //         return `${props.currency_symbol} ${props.wallet_balance}`
                    //     },
                    // },
                    {
                        field: 'created_at',
                        label: 'Created On',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
                    },
                ];
            }
        }
    },
    mounted(){
        this.$refs.userTable.loadItems();
    }
}
</script>
    